#block-search, #block-search * {
    box-sizing: border-box;
}
.tl-block {
    position: relative;
}
#block-search-main {
    background: rgba(0, 0, 0, 0.54);
    width: 100%;
    position: absolute !important;
    bottom: 0;
    z-index: 9;
}
.tl-container {
    padding: 0 25px;
    max-width: 1450px;
    margin: 0 auto;
}
#bookButton {
    display: none;
}
@media screen and (max-width: 405px) {
    #block-search-main {
        position: relative !important;
        bottom: auto;
        background: rgba(0, 0, 0, 0.84);
    }
}