.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navBarTop {
  padding-top: 50px;
}


.head-top {
  /* The image used */
  background-image: url("components/assets/header-bkg.jpg");
  font-family: Lucida Console, Monospace;
  min-height: 1000px;

  /* Center and scale the image nicely */
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;

  /* Needed to position the navbar */
  position: relative;
}

.hotelName {
  font-family: Lucida Console, Monospace;
  font-size: 22px;
  color : white;
  font-weight: bold;
}


.hotelDescription{
  font-family: Lucida Console, Monospace;
  font-size: 18px;
  color : white;
}

@media screen and (min-width: 601px) {
  .navBarBottom{
    position: relative;
    top: 350px;
    padding-left: 100px;
    color: white;
    font-family: Lucida Console, Monospace;
  }

  .navbar-custom .navbar-brand,
  .navbar-custom .navbar-text {
      color: white;
      font-size: 22px;
      font-family: Lucida Console, Monospace;
  }/* change the link color */
  .navbar-custom .navbar-nav .nav-link {
      color: white;
      font-size: 22px;
      font-family: Lucida Console, Monospace;
  }


  #navBarBottomLeft{
    font-size: 3vw;
  }

  #navBarBottomRight{
    text-align: right;
    font-size: 1.2vw;
  }

  .contactUs {
    font-family: Lucida Console, Monospace;
    font-size: 1.0vw;
    color : #5F5D60;
    text-align: center;
  }

  .leaflet-container {
    width: 100%;
    height: 400px;
  }

    /* t r b l */
    #imageGallery {
      padding: 100px 0px 100px 0px;
    }

    #contactUs{
      padding: 100px 0px 100px 0px;
    }

    .roomName {
      font-family: Lucida Console, Monospace;
      font-size: 22px;
      color : black;
      font-weight: bold;
    }

    .app {
      font-family: Lucida Console, Monospace;
      font-size: 45px;
    }

    .description {
      font-family: Lucida Console, Monospace;
      font-size: 14px;
      align: left;

    }

    .map {
      width : 560px;
      height : 400px;
    }
}

@media screen and (max-width: 600px) {
  .navbar-custom .navbar-brand,
  .navbar-custom .navbar-text {
      color: white;
      font-size: 14px;
      font-family: Lucida Console, Monospace;
  }/* change the link color */
  .navbar-custom .navbar-nav .nav-link {
      color: white;
      font-size: 14px;
      font-family: Lucida Console, Monospace;
  }

  #navBarBottomLeft{
    font-size: 24px;
  }

  #navBarBottomRight{
    text-align: right;
    font-size: 14px;
  }

  .contactUs {
    font-family: Lucida Console, Monospace;
    font-size: 14px;
    color : #5F5D60;
    text-align: center;
  }

  .leaflet-container {
    width: 100%;
    height: 50vh;
  }

  /* t r b l */
  #imageGallery {
    padding: 1.2vw 0px 1.2vw 0px;
  }

  #contactUs{
    padding:  1.2vw 0px 1.2vw 0px;
  }

  .roomName {
      font-family: Lucida Console, Monospace;
      font-size: 14px;
      color : black;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .app {
      font-family: Lucida Console, Monospace;
      font-size: 16px;
    }

    .description {
      font-family: Lucida Console, Monospace;
      font-size: 10px;
      align: left;

    }

    .navBarBottom{
      position: relative;
      top: 250px;
      padding-left: 100px;
      color: white;
      font-family: Lucida Console, Monospace;
    }

    .map {
      width : 360px;
      height : 400px;
    }
}

.mobileNumber {
  background-color: rgba(255, 255, 255, 0.2);
}

#bookButton{
  font-size: 14px;
  padding: 20px 40px 20px 40px;
  background-color : #E05105;
  border-radius: 0px;
  border-spacing:50px;
}


.leaflet-tile-pane {
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
}